<template>
  <apexchart
    type="rangeBar"
    :height="100"
    :options="chartOptions"
    :series="rowsData"
  />
</template>

<script>
import { CliniciansStatusColors } from "../../misc/constants";

export default {
  name: "ClinicianDynamicTimeline",

  props: {
    statusesLogs: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const chartOptions = {
      chart: {
        width: "100%",
        type: "rangeBar",
        offsetX: 0,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "50%",
          rangeBarGroupRows: true,
          distributed: true,
          borderRadius: 8,
        },
      },
      legend: {
        show: false,
      },
      fill: {
        type: "solid",
      },
      xaxis: {
        type: "datetime",
        tickPlacement: "on",
        labels: {
          formatter(value, timestamp) {
            if (timestamp % 2 === 0) {
              return new Date(value).toLocaleDateString(undefined, {
                year: "numeric",
                month: "short",
              });
            }
            return "";
          },
          offsetX: 25,
        },
      },
      yaxis: {
        show: false,
        labels: {
          show: false,
        },
      },
      tooltip: {
        followCursor: false,
        x: {
          show: false,
        },
      },
      grid: {
        show: true,
        padding: {
          left: -15,
          right: 0,
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };

    return {
      chartOptions,
    };
  },

  computed: {
    rowsData() {
      return this.statusesLogs.map((log, ind) => {
        const label = "Status";
        const status = log.category;
        const startDate = new Date(log.createdAt).getTime();
        const endDate = this.statusesLogs[ind + 1]
          ? new Date(this.statusesLogs[ind + 1].createdAt).getTime()
          : new Date().getTime();
        const color = CliniciansStatusColors[status];
        return {
          name: status,
          data: [{ x: label, y: [startDate, endDate], fillColor: color }],
        };
      });
    },
  },

  mounted() {
    this.chartOptions = {
      ...this.chartOptions,
      xaxis: {
        ...this.chartOptions.xaxis,
        min: new Date(this.statusesLogs[0].createdAt).getTime(),
      },
    };
  },
};
</script>
