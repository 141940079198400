import { TableRowsPerPage } from "../../misc/constants";

export const tableHeaders = [
  {
    text: "Case Reference",
    align: "start",
    sortable: true,
    value: "case.caseReference",
    width: "22%",
  },
  {
    text: "Case Date",
    value: "case.caseDate",
    sortable: true,
    align: "start",
    width: "23%",
  },
  {
    text: "Status",
    value: "status",
    sortable: false,
    align: "start",
    width: "20%",
  },
  {
    text: "Score, %",
    value: "audit.score",
    sortable: true,
    align: "start",
    width: "17%",
  },
  {
    text: "Import Date",
    value: "case.createdAt",
    sortable: false,
    align: "start",
    width: "22%",
  },
];

export const tableFooterOptions = {
  "items-per-page-options": TableRowsPerPage,
};

export const defaultItemsPerPage = 10;
