<template>
  <apexchart
    type="pie"
    :height="350"
    :options="diagramOptions"
    :series="diagramOptions.series"
  />
</template>

<script>
import { CasesStatusColors } from "../../misc/constants";

export default {
  name: "ClinicianStatisticDiagram",

  props: {
    statistic: {
      type: Object,
      required: true,
    },
  },

  data() {
    const defaultDiagramOptions = {
      chart: {
        height: "300px",
        width: "100%",
        type: "pie",
      },
      legend: {
        position: "bottom",
        itemMargin: {
          vertical: 5,
          horizontal: 10,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        intersect: true,
        shared: false,
        fixed: {
          enabled: true,
          position: "topRight",
          offsetX: 10,
          offsetY: 10,
        },
      },
    };

    const emptyOptions = {
      series: [1],
      labels: ["No cases statistic found"],
      colors: ["#f0f0f0"],
    };

    return {
      defaultDiagramOptions,
      emptyOptions,
    };
  },

  computed: {
    diagramOptions() {
      if (!Object.values(this.statistic).some(Boolean))
        return { ...this.defaultDiagramOptions, ...this.emptyOptions };

      const statuses = Object.keys(this.statistic);

      return {
        ...this.defaultDiagramOptions,
        colors: statuses.map((status) => CasesStatusColors[status]),
        labels: statuses.map((status) => `${status}, %`),
        series: Object.values(this.statistic),
      };
    },
  },
};
</script>
