<template>
  <v-dialog
    :value="dialog"
    width="616px"
    :fullscreen="isMobile"
    :persistent="isMobile"
    :style="!isMobile ? 'z-index:20001;' : ''"
    @click:outside="handleCancelBtnClick"
  >
    <v-card class="pt-6 clinician-form-container">
      <breadcrumbs
        v-if="isMobile"
        :items="breadCrumbItems"
        :style="isMobile ? 'padding-top: 113px !important;' : ''"
        class="ma-0 pa-6"
      />

      <v-card-title class="pa-6 pt-0 text-h4"> Change </v-card-title>

      <v-form class="pa-6 pt-0">
        <v-select
          :value="category"
          :items="categoryItems"
          :error-messages="categoryErrors"
          label="Category"
          :menu-props="{ bottom: true, offsetY: true }"
          outlined
          class="mb-2"
          @change="handleSelectUpdate($event, 'category')"
          @blur="$v.category.$touch()"
        >
          <template v-slot:selection="{ item }">
            <div class="d-flex flex-start align-center">
              <clinicians-name-badge :clinician-status="item" class="mr-2" />
              <span>{{ item }}</span>
            </div>
          </template>

          <template v-slot:item="{ item }">
            <div class="d-flex flex-start align-center">
              <clinicians-name-badge :clinician-status="item" class="mr-2" />
              <span>{{ item }}</span>
            </div>
          </template>
        </v-select>

        <v-select
          :value="positionName"
          :items="positionsOptions"
          :menu-props="{ bottom: true, offsetY: true }"
          label="Position"
          outlined
          class="mb-2"
          @change="handlePosition($event, 'positionName')"
          @blur="$v.positionName.$touch()"
        />

        <v-text-field
          :value="name"
          disabled
          label="Full Name"
          outlined
          placeholder="Enter clinician name"
          class="mb-2"
        />
        <ClinicalServicesMultiselect
          v-if="isModalVisible"
          :services="clinicalServices"
          class="mb-8"
          @change="handleSelectUpdate($event, 'clinicalServices')"
        />
        <v-text-field
          :value="email"
          :disabled="!!clinician?.email"
          :error-messages="emailErrors"
          :messages="emailHint"
          clearable
          label="Email"
          outlined
          placeholder="Enter user email"
          class="mb-2"
          @input="handleTextFieldInput($event, 'email')"
          @blur="$v.email.$touch()"
        />

        <v-select
          :value="managerName"
          :items="managerOptions"
          :menu-props="{ bottom: true, offsetY: true }"
          label="Manager"
          outlined
          class="mt-0"
          @change="handleManager($event, 'managerName')"
          @blur="$v.managerName.$touch()"
        />
        <div
          class="clinician-form-container__btn-container d-flex align-center"
          :class="btnContainerClass"
        >
          <v-btn
            :disabled="submitDisabled"
            :loading="loading"
            color="primary"
            rounded
            large
            type="submit"
            variant="elevated"
            class="text-subtitle-2 font-weight-bold"
            @click.prevent="handleFormSubmit"
          >
            Save Changes
          </v-btn>
          <v-btn
            :disabled="loading"
            text
            btn-container-class
            large
            color="primary"
            class="text-subtitle-2 font-weight-bold"
            @click="handleCancelBtnClick"
          >
            Cancel
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { isEqual } from "lodash";
import { required, minLength, email } from "vuelidate/lib/validators";
import CliniciansNameBadge from "../common/CliniciansNameBadge.vue";
import Breadcrumbs from "../common/Breadcrumbs.vue";
import { ClinicianStatuses } from "../../misc/constants";
import ClinicalServicesMultiselect from "@/components/controls/ClinicalServicesMultiselect.vue";

export default {
  name: "ClinicianEditModal",

  components: {
    ClinicalServicesMultiselect,
    CliniciansNameBadge,
    Breadcrumbs,
  },

  mixins: [validationMixin],

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    clinician: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    managers: {
      type: Array,
      required: true,
    },
    managerOptions: {
      type: Array,
      required: true,
    },
    isModalVisible: {
      type: Boolean,
      default: false,
    },
    positions: {
      type: Array,
      required: true,
    },
    positionsOptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    const categoryItems = Object.values(ClinicianStatuses);

    const breadCrumbItems = [
      {
        title: "Clinicians",
        disabled: false,
        to: "/clinicians",
      },
      {
        title: "Clinician Profile (Edit)",
        disabled: true,
      },
    ];

    return {
      categoryItems,
      breadCrumbItems,
      category: null,
      name: null,
      email: null,
      positionName: null,
      managerId: null,
      managerName: null,
      position: null,
      clinicalServices: [],
    };
  },

  validations: {
    category: { required },
    name: { required, minLength: minLength(5) },
    email: { required, email },
    positionName: {},
    managerName: {},
    clinicalServices: {},
  },

  computed: {
    submitDisabled() {
      return (
        this.$v.$invalid ||
        !this.isFormDataChanged ||
        this.clinician.deactivatedAt !== null
      );
    },
    btnContainerClass() {
      return {
        "flex-row-reverse": !this.isMobile,
        "flex-column": this.isMobile,
        "justify-start": !this.mobile,
      };
    },
    categoryErrors() {
      const errors = [];
      if (!this.$v.category.$dirty) return errors;
      !this.$v.category.required && errors.push("Status is required");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email is required");
      !this.$v.email.email && errors.push("Must be valid e-mail");
      return errors;
    },
    isFormDataChanged() {
      const textFields = ["email", "category", "position", "managerId"];
      const isTextFieldsChanged = textFields.some(
        (key) => this[key] !== this.clinician[key]
      );
      const multiSelectFields = ["clinicalServices"];
      const isMultiSelectFieldsChanged = multiSelectFields.some((key) => {
        return this[key]?.length === this.clinician[key]?.length
          ? !isEqual(this[key].sort(), this.clinician[key].sort())
          : true;
      });
      return isTextFieldsChanged || isMultiSelectFieldsChanged;
    },
    emailHint() {
      return this.clinician?.email
        ? null
        : "Please, make sure that this is the correct email address. You won't be able to change it next time.";
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  watch: {
    dialog(newValue) {
      if (newValue) {
        this.initModalData();
      }
    },
  },

  methods: {
    handlePosition(value, key) {
      this.handleSelectUpdate(value, key);
      this.handlePositionId();
    },
    handleManager(value, key) {
      this.handleSelectUpdate(value, key);
      this.handleManagerId();
    },
    handlePositionId() {
      if (this.positions && this.positionName) {
        this.position = this.positions.find(
          (item) => item.name === this.positionName
        )._id;
      }
    },
    handlePositionName() {
      if (this.positions && this.position) {
        this.positionName = this.positions.find(
          (item) => item.id === this.position
        ).name;
      }
    },
    handleManagerId() {
      if (this.managers && this.managerName) {
        this.managerId = this.managers.find(
          (item) => item.name === this.managerName
        ).id;
      }
    },
    handleManagerName() {
      if (this.managers && this.managerId) {
        this.managerName = this.managers.find(
          (item) => item.id === this.managerId
        ).name;
      }
    },
    handleCancelBtnClick() {
      this.resetData();
      this.$emit("cancel", false);
    },
    handleSelectUpdate(value, key) {
      this[key] = value;
      this.$v[key].$touch();
    },
    handleTextFieldInput(value, key) {
      this[key] = value;
    },
    handleFormSubmit() {
      const updateFields = [
        "email",
        "category",
        "position",
        "managerId",
        "clinicalServices",
      ];
      const body = updateFields.reduce((acc, key) => {
        if (this[key] !== this.clinician[key]) {
          acc[key] =
            typeof this[key] === "string" && !this[key].length
              ? null
              : this[key];
        }
        return acc;
      }, {});
      this.$emit("submit", body);
    },
    initModalData() {
      const { name, email, category, position, managerId, clinicalServices } =
        this.clinician;
      this.name = name;
      this.email = email;
      this.category = category || "";
      this.position = position || "";
      this.managerId = managerId || "";
      this.clinicalServices = clinicalServices || [];
      this.handleManagerName();
      this.handlePositionName();
    },
    resetData() {
      this.name = null;
      this.positionName = null;
      this.email = null;
      this.category = null;
      this.managerId = null;
      this.managerName = null;
      this.position = null;
      this.positionName = null;
      this.clinicalServices = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.clinician-form-container {
  &__btn-container {
    @media (max-width: 768px) {
      & button {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
